import { HelmetProps } from 'react-helmet';

export const helmet: HelmetProps = {
    htmlAttributes: { lang: 'ru' },
    title: '',
    titleTemplate: '%s',
    meta: [
        {
            name: 'description',
            content:
                'Полный спектр банковских услуг для физических и юридических лиц: кредитование, вклады и депозиты, ведение счетов. Тарифы. Онлайн-банкинг. Адреса отделений и банкоматов.'
        },
        {
            name: 'msapplication-square70x70logo',
            content: '/f/media/logo/alfa-logo-white-70.png'
        },
        {
            name: 'msapplication-square150x150logo',
            content: '/f/media/logo/alfa-logo-white-150.png'
        },
        {
            name: 'msapplication-square310x310logo',
            content: '/f/media/logo/alfa-logo-white-310.png'
        },
        {
            name: 'application-name',
            content: 'Alfa-Bank'
        },
        {
            name: 'theme-color',
            content: '#F3F4F5'
        }
    ]
};
